
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

/**
 * custom stepper component, used own implementation over the existing v-stepper because the design was hard to fit
 * inside the vuetify component, the v-stepper has only a few options to change
 */
@Component
export default class CustomStepperComponent extends Vue {
  @Prop({required: true})
  private currentStep!: number;

  @Prop({default: 4})
  private steps!: number;

  @Prop()
  private onStepClicked?: (step: number) => void;


  /**
   * is called when user clicks on a step, calls callback function with the clicked step as parameter
   * @param step
   * @private
   */
  private onStepPressed(step: number) {
    if(this.onStepClicked) this.onStepClicked!(step);
  }
}
